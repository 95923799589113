import { TutorialStore, useTutorialStore } from "./tutorialStore";
import { ModalState, useModalStore } from "./modalStore";
import { LeaderboardState, useLeaderboardStore } from "./leaderboardStore";
import { PlayerState, usePlayerStore } from "./playerStore";
import { GameStateStore, useGameStateStore } from "./gameStateStore";
import { ApplicationStateStore, useApplicationStore } from "./applicationStore";

export type AplicationStore = TutorialStore &
  ModalState &
  LeaderboardState &
  PlayerState &
  GameStateStore &
  ApplicationStateStore;

const mergeStores = (...stores: any[]): AplicationStore => {
  return stores.reduce((acc, store) => ({ ...acc, ...store }), {});
};

export const applicationStore = mergeStores(
  useTutorialStore.getState(),
  useModalStore.getState(),
  useLeaderboardStore.getState(),
  usePlayerStore.getState(),
  useGameStateStore.getState(),
  useApplicationStore.getState(),
);
