import React, { useMemo } from "react";
import Slider from "react-slick";
interface CarouselProps {
  children: React.ReactNode;
}

const MIN_PLAYER_LENGTH = 7;

export const PlayersCarousel: React.FC<CarouselProps> = ({ children }) => {
  const itemsLength = useMemo(() => React.Children.count(children), [children]);

  const autoScroll = useMemo(() => {
    return itemsLength > MIN_PLAYER_LENGTH;
  }, [itemsLength]);

  const settings = useMemo(() => {
    return {
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToScroll: 1,
      slidesToShow: Math.min(MIN_PLAYER_LENGTH, itemsLength),
      autoplay: autoScroll,
      arrows: false,
      autoplaySpeed: 2000,
    };
  }, [itemsLength, itemsLength]);

  return (
    <>
      <Slider vertical {...settings}>
        {children}
      </Slider>
    </>
  );
};
