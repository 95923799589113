import React, { useContext, useEffect, useMemo } from "react";

import cn from "classnames";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLeaderboardStore } from "../../store/leaderboardStore";
import { useModalStore } from "../../store/modalStore";
import Modal from "../../components/Modal/modal";
import SocketContext from "../../context/socket-context";
import { outgoingMessageTypes } from "../../constants/message-types";
import { PlayersCarousel } from "../../components/players-carousel";
import styles from "./styles.module.scss";

export const LEADERBOARD_MODAL_ID = "LEADERBOARD_MODAL_ID";
const POSITIONS = ["middle", "left", "right"];
const PLACEMENTS = ["first", "second", "third"];

const NOT_VISIBLE_PATHS = ["/", "/game"];

const LeaderboardScreen = () => {
  const { t } = useTranslation();
  const socket = useContext(SocketContext);
  const { isModalOpen } = useModalStore();
  const { leaderboard, isLeaderboardEmpty } = useLeaderboardStore((state) => state);
  const loaction = useLocation();

  const isOpen = useMemo(() => {
    return isModalOpen(LEADERBOARD_MODAL_ID) && !NOT_VISIBLE_PATHS.includes(loaction.pathname);
  }, [isModalOpen(LEADERBOARD_MODAL_ID), loaction.pathname]);

  const autoScroll = useMemo(() => {
    return leaderboard?.length > 8;
  }, [leaderboard]);

  useEffect(() => {
    if (!isModalOpen(LEADERBOARD_MODAL_ID)) {
      return;
    }

    socket?.emit(outgoingMessageTypes.getLeaderboardRequest);
  }, [isModalOpen(LEADERBOARD_MODAL_ID)]);

  return (
    <Modal isOpen={isOpen} outsideTitle={t("leaderboard")} modalClassName={styles.leaderboardModalContainer}>
      <div>
        <div className={styles.rankingTable}>
          <div className={styles.header}>
            <div className={cn(styles.wrapper, "text-xl regular uppercase white-alpha-40")}>
              <span>{t("rank")}</span>
              <span className="pl-13">{t("name")}</span>
              <span>{t("1st")}</span>
              <span>{t("2nd")}</span>
              <span>{t("3rd")}</span>
              <span>{t("score")}</span>
            </div>
          </div>
          <>
            <PlayersCarousel>
              {leaderboard.map((player, index) => (
                <div key={player.name}>
                  <div
                    className={cn(styles.container, {
                      [styles[PLACEMENTS[index]]]: !isLeaderboardEmpty,
                      [styles.autoScroll]: autoScroll,
                    })}
                    style={{ animationDelay: `${0.25 + index * 0.08}s`, boxSizing: "unset" }}
                  >
                    {index < 3 && !isLeaderboardEmpty && <div className={styles.shining} />}
                    <div className={cn({ [styles.detailsContainerBorder]: index < 3 && !isLeaderboardEmpty })}>
                      <div className={cn({ [styles.detailsContainer]: index < 3 && !isLeaderboardEmpty })}>
                        <div className={cn(styles.largeItemContainer, styles.wrapper)}>
                          <span className="text-xl regular white-alpha-70">{index + 1}.</span>
                          <div className={cn(styles.playerInfo)}>
                            <div className={cn(styles.playerImageContainer, `bg-${player?.color}`)}>
                              {!player.image && (
                                <div className={styles.capitalLetter}> {player.name.charAt(0).toUpperCase()} </div>
                              )}
                              {player.image && (
                                <img src={player.image} alt={player.name} className={styles.playerAvatar} />
                              )}
                            </div>

                            <span className="display-m bold uppercase pl-3">{player.name}</span>
                          </div>

                          <span className="display-m regular">{player.first || "-"}</span>
                          <span className="display-m regular">{player.second || "-"}</span>
                          <span className="display-m regular">{player.third || "-"}</span>
                          <span className={cn(styles.playerScore, "w-100 display-l bold")}>
                            {isLeaderboardEmpty ? "" : (player.points ?? 0)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </PlayersCarousel>
          </>
        </div>
      </div>
    </Modal>
  );
};

export default LeaderboardScreen;

/*
Later put it in the header if player avatar needs to be shown at the leaderboard header
<div>
              <div className={styles.leaderboardContainer}>
                {leaderboard.slice(0, 3).map((player, index) => (
                  <div key={index} className={cn(styles.leaderboardItem, styles[POSITIONS[index]])}>
                    <div className={cn(styles.placementBadge, styles[PLACEMENTS[index]])}>
                      <div className={styles.badgeBorder}>
                        <div className={cn(styles.badgeContainer, "display-s bold uppercase")}>{index + 1}</div>
                      </div>
                    </div>
                    <div className={cn(styles.placementAvatarContainer, styles[PLACEMENTS[index]], "mb-3")}>
                      {<div className={styles.shining} />}
                      {!player.image && (
                        <div
                          className={cn(
                            styles.placementAvatar,
                            styles.capitalLetter,
                            styles.placement,
                            styles[PLACEMENTS[index]],
                            {
                              [`bg-${player.color}`]: !player.image,
                            },
                          )}
                        >
                          {player.name.charAt(0).toUpperCase()}
                        </div>
                      )}
                      {player.image && (
                        <img
                          src={player.image}
                          alt={player.name}
                          className={cn(styles.placementAvatar, styles[PLACEMENTS[index]])}
                        />
                      )}
                    </div>
                    <div className={cn(styles.leaderboardResult, "display-s bold uppercase")}>
                      <span>{player.name}</span>
                      <span>{player.points}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
*/
