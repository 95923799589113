import { createWithEqualityFn } from "zustand/traditional";
import { combine } from "zustand/middleware";
import { shallow } from "zustand/shallow";

export type VideoAction = "stop" | "play";

const initialState: TutorialState = {
  step: undefined,
  videoAction: undefined,
};

interface TutorialState {
  step: number | undefined;
  videoAction: VideoAction | undefined;
}

interface TokenMutations {
  setStep: (step: number | undefined) => void;
  setVideoAction: (videoAction: VideoAction) => void;
  removeStep: () => void;
}

export type TutorialStore = TutorialState & TokenMutations;

const mutations = (set: (partial: Partial<TutorialState>) => void): TokenMutations => ({
  setStep: (step: number | undefined) => set({ step }),
  setVideoAction: (videoAction: VideoAction) => set({ videoAction }),
  removeStep: () => set({ step: undefined }),
});

export const useTutorialStore = createWithEqualityFn(combine(initialState, mutations), shallow);
