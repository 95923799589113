export const CONFIGURATION_CHANGED = "CONFIGURATION_CHANGED";
export const GAME_READY = "GAME_READY";
export const GAME_OVER = "GAME_OVER";
export const PLAYER_ELIMINATED = "PLAYER_ELIMINATED";
export const THROW_DETECTED = "THROW_DETECTED";
export const TAKEOUT_STARTED = "TAKEOUT_STARTED";
export const TAKEOUT_FINISHED = "TAKEOUT_FINISHED";
export const START_GAME = "START_GAME";
export const PLAYER_LIST_CHANGED = "PLAYER_LIST_CHANGED";

export type GameScene = "in_game" | "game_list" | "start_screen";
