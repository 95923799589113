import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";
import { CssBaseline, ThemeProvider, withStyles } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import { Socket } from "socket.io-client";
import CloseIcon from "@material-ui/icons/Close";
import PlayerListScreen from "./containers/PlayerListScreen/player-list-screen";
import GameScreen from "./containers/game-screen";
import StartScreen from "./containers/start-screen";
import LoadGameScreen from "./containers/load-game-screen";
import { initSocket, initEventHandler } from "./socket";
import theme from "./theme";
import SocketContext from "./context/socket-context";
import { outgoingMessageTypes } from "./constants/message-types";
import TakeOverControl from "./components/Notification/TakeOverControl/take-over-control";
import "./assets/stylesheets/main.scss";
import GameTutorialScreen from "./containers/GameTutorial/game-tutorial-screen";
import GameListScreen from "./containers/GameListScreen/game-list-screen";
import { applicationStore } from "./store/utils";
import LeaderboardScreen from "./containers/Leaderboard/leaderboard";
import BoardStatus from "./components/Notification/BoardStatus/board-status";

export const SERIAL_NUMBER = "serialNumber";

export const routes = [
  { path: "/game-list", element: <GameListScreen /> },
  { path: "/player-list", element: <PlayerListScreen /> },
  { path: "/game-tutorial", element: <GameTutorialScreen /> },
  { path: "/game", element: <GameScreen /> },
  { path: "/load-game", element: <LoadGameScreen /> },
  { path: "/", element: <StartScreen /> },
];

const GlobalCss = withStyles({
  "@global": {
    ".MuiSkeleton-root": {
      background: "grey",
    },
  },
})(() => null);

const App = () => {
  const [searchParams] = useSearchParams();
  const [initialized, setInitialized] = useState(false);
  const [socket, setSocket] = useState<Socket | null>(null);
  const navigate = useNavigate();

  const notify = () => {
    toast("This resolution is not supported. Please use in FullHD device for best user experience.", {
      style: { backgroundColor: "#f1c40f", color: "#000", fontWeight: "bold" },
      position: "top-center",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const checkOrientation = () => {
    if (window.matchMedia("(orientation: landscape)").matches) {
      notify();
    }
  };

  const handleUnload = useCallback(() => {
    socket?.emit(outgoingMessageTypes.reload);
  }, [socket]);

  const handleLoad = useCallback(() => {
    const serialNumber = sessionStorage.getItem(SERIAL_NUMBER) ?? "";
    navigate(`/?serialNumber=${serialNumber}`, { replace: true });
  }, [navigate]);

  const checkDimensionsOnLoad = () => {
    if (window.innerHeight > window.innerWidth) {
      notify();
    }
  };

  useEffect(() => {
    const serialNumber = searchParams.get("serialNumber");

    if (serialNumber) {
      sessionStorage.setItem(SERIAL_NUMBER, serialNumber.toString());
    }

    handleLoad();

    setSocket(initSocket(sessionStorage.getItem(SERIAL_NUMBER) as string));
  }, []);

  useEffect(() => {
    if (socket) {
      initEventHandler(navigate, socket, applicationStore);
      setInitialized(true);
    }
  }, [socket]);

  useEffect(() => {
    window.addEventListener("orientationchange", checkOrientation);

    if (navigator.userAgent.includes("Firefox")) {
      window.addEventListener("beforeunload", handleUnload);
    } else {
      window.addEventListener("pagehide", handleUnload);
    }

    return () => {
      window.removeEventListener("orientationchange", checkOrientation);

      if (navigator.userAgent.includes("Firefox")) {
        window.removeEventListener("beforeunload", handleUnload);
      } else {
        window.removeEventListener("pagehide", handleUnload);
      }
    };
  }, [handleLoad, handleUnload]);

  useEffect(() => {
    checkDimensionsOnLoad();
  }, []);

  const CustomCloseButton = ({ closeToast }: any) => <CloseIcon onClick={closeToast}></CloseIcon>;

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={false}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        closeButton={CustomCloseButton}
        draggable
      />
      {initialized && socket && (
        <SocketContext.Provider value={socket}>
          <ThemeProvider theme={theme}>
            <div className="moving-background"></div>
            <GlobalCss />
            <BoardStatus />
            <TakeOverControl />
            <LeaderboardScreen />
            <CssBaseline>
              <Routes>
                {routes.map((route) => (
                  <Route key={route.path} path={route.path} element={route.element} />
                ))}
              </Routes>
            </CssBaseline>
          </ThemeProvider>
        </SocketContext.Provider>
      )}
    </>
  );
};

export default App;
