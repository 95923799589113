import React, { useEffect, useState } from "react";

import cn from "classnames";
import { useLocation } from "react-router-dom";

import "./board-status.scss";
import { useTranslation } from "react-i18next";
import { useApplicationStore } from "../../../store/applicationStore";

const statusMessages = {
  offline: "offline",
  online: "online",
  in_use: "",
};

const BoardStatus = () => {
  const { t } = useTranslation();
  const [firstLoading, setFirstLoading] = useState(true);
  const [showBoardStatus, setShowBoardStatus] = useState(false);
  const sbcStatus = useApplicationStore((state) => state.sbcStatus);
  const location = useLocation();

  useEffect(() => {
    if (!firstLoading && sbcStatus === "online") {
      setTimeout(() => setShowBoardStatus(false), 3000);
    } else if (sbcStatus === "offline") {
      setFirstLoading(false);
      setShowBoardStatus(true);
    }
  }, [sbcStatus]);

  if (location.pathname === "/" || firstLoading) {
    return null;
  }

  return (
    <div
      className={cn("board-status-overlay", {
        "animate-in": showBoardStatus,
        "animate-out": !showBoardStatus,
        offline: sbcStatus === "offline",
        online: sbcStatus === "online",
      })}
    >
      <span className="display-xl bold">{t(statusMessages[sbcStatus])}</span>
    </div>
  );
};

export default BoardStatus;
