import { createWithEqualityFn } from "zustand/traditional";
import { combine } from "zustand/middleware";
import { shallow } from "zustand/shallow";
import { SbcStatusType } from "../models/sbc-status";

interface ApplicationState {
  sbcStatus: SbcStatusType;
  isTakeOverControlVisible: boolean;
  background: string;
}

const initialState: ApplicationState = {
  sbcStatus: "online",
  isTakeOverControlVisible: false,
  background: "GRADIENT_1",
};

interface ApplicationStateMutations {
  setBackground: (background: string) => void;
  setSbcStatus: (status: SbcStatusType) => void;
  setTakeOverControlVisible: (isVisible: boolean) => void;
}

export type ApplicationStateStore = ApplicationState & ApplicationStateMutations;

const mutations = (set: (partial: Partial<ApplicationStateStore>) => void) => ({
  setBackground: (background: string) => set({ background }),
  setSbcStatus: (status: SbcStatusType) => set({ sbcStatus: status }),
  setTakeOverControlVisible: (isVisible: boolean) => set({ isTakeOverControlVisible: isVisible }),
});

export const useApplicationStore = createWithEqualityFn(combine(initialState, mutations), shallow);
