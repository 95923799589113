import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

export interface ModalState {
  modals: Record<string, boolean>; // Keeps track of modals and their open state
  openModal: (modalId: string) => void; // Open a specific modal
  closeModal: (modalId: string) => void; // Close a specific modal
  closeAllModals: () => void; // Close all modals
  isModalOpen: (modalId: string) => boolean; // Check if a modal is open
}

export const useModalStore = createWithEqualityFn<ModalState>(
  (set, get) => ({
    modals: {},

    openModal: (modalId: string) =>
      set((state) => ({
        modals: { ...state.modals, [modalId]: true },
      })),

    closeModal: (modalId: string) =>
      set((state) => ({
        modals: { ...state.modals, [modalId]: false },
      })),

    closeAllModals: () =>
      set((state) => ({
        modals: Object.keys(state.modals).reduce((acc, modalId) => ({ ...acc, [modalId]: false }), {}),
      })),

    isModalOpen: (modalId: string) => !!get().modals[modalId],
  }),
  shallow,
);
