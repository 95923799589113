import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

import { LeaderBoard } from "../models/leaderboard";
import { usePlayerStore } from "./playerStore";

export interface LeaderboardState {
  isLeaderboardEmpty: boolean;
  leaderboard: LeaderBoard[];
  setLeaderboard: (leaderboard: LeaderBoard[]) => void;
}

export const useLeaderboardStore = createWithEqualityFn<LeaderboardState>(
  (set) => ({
    leaderboard: [],
    isLeaderboardEmpty: false,

    setLeaderboard: (leaderboard: LeaderBoard[]) => {
      const players = usePlayerStore.getState().playerList;

      //If there are no played games, we set the lobby players as the leaderboard
      if (leaderboard.length === 0) {
        const leaderboard: LeaderBoard[] = players.map((player) => ({
          ...player,
          points: 0,
          first: 0,
          second: 0,
          third: 0,
        }));

        return set(() => ({
          leaderboard,
          isLeaderboardEmpty: true,
        }));
      }

      return set(() => ({
        leaderboard,
        isLeaderboardEmpty: false,
      }));
    },
  }),
  shallow,
);
