import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Paper, Typography } from "@material-ui/core";
import { QRCode } from "react-qrcode-logo";
import { withTheme } from "@material-ui/core/styles";
import { URI } from "../../../constants/config";
import { REFRESH_TOKEN } from "../../../socket/event-handler";
import { getConfigDeviceUrl } from "../../../utils/configurator-device-url";

import "./take-over-control.scss";
import { useApplicationStore } from "../../../store/applicationStore";

const TakeOverControl = () => {
  const refreshToken = sessionStorage.getItem(REFRESH_TOKEN);
  const [isVisible, setIsVisible] = useState(false);

  const isTakeOverControlVisible = useApplicationStore((state) => state.isTakeOverControlVisible);

  const configDeviceConnectUrl = useMemo(() => {
    return `${getConfigDeviceUrl(URI)}/?refreshToken=${refreshToken}`;
  }, [refreshToken]);

  const handleAnimation = useCallback(({ animationName }: React.AnimationEvent<HTMLElement>) => {
    if (animationName === "slide-down") {
      setIsVisible(false);
    }
  }, []);

  useEffect(() => {
    if (isTakeOverControlVisible) {
      setIsVisible(true);
    }
  }, [isTakeOverControlVisible]);

  if (!refreshToken || !isVisible) return null;

  return (
    <div className="foggy-background">
      <Box
        className={`take-over-container ${isTakeOverControlVisible ? "show" : "hide"}`}
        onAnimationEndCapture={handleAnimation}
        width={1}
        textAlign="center"
      >
        <Paper style={{ height: "auto", width: 600 }}>
          <Box width={1} height="auto" display="flex" flexDirection="column">
            <Typography
              component="div"
              variant="h4"
              align="center"
              style={{ fontWeight: "bold", marginTop: 12, marginBottom: 24 }}
              color="textPrimary"
            >
              To take control, scan the QR code below.
            </Typography>

            <Box textAlign="center" marginBottom="1.5rem">
              <QRCode value={configDeviceConnectUrl} />
            </Box>
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default withTheme(TakeOverControl);
