import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";

import cn from "classnames";

import { useTranslation } from "react-i18next";
import { useTutorialStore } from "../../store/tutorialStore";
import { useModalStore } from "../../store/modalStore";
import Modal from "../../components/Modal/modal";
import { useGameStateStore } from "../../store/gameStateStore";
import styles from "./styles.module.scss";

export const TUTORIAL_MODAL_ID = "TUTORIAL_MODAL_ID";

const ProgressBar = ({ progress }: any) => {
  return <div className={styles.progressBar} style={{ height: `${progress}%` }} />;
};

interface AccordionItemProps {
  title: string;
  isActive: boolean;
  children: React.ReactNode;
}

const AccordionItem = ({ title, isActive, children }: AccordionItemProps) => {
  return (
    <div className={cn(styles.accordionItem, { [styles.active]: isActive })}>
      <div className={styles.accordionHeader}>
        <span className="display-m bold uppercase">{title}</span>
      </div>
      {<div className={styles.accordionContent}>{children}</div>}
    </div>
  );
};

const GameTutorialScreen = ({ isModalVersion }: { isModalVersion?: boolean }) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const [progress, setProgress] = useState(0);
  const [imageError, setImageError] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const animationFrameRef = useRef<number | null>(null);
  const { closeModal, isModalOpen } = useModalStore();
  const { step, videoAction, setVideoAction, removeStep, setStep } = useTutorialStore((state) => state);
  const selectedGame = useGameStateStore((state) => state.selectedGame);

  const tutorialSteps = useMemo(() => {
    return selectedGame?.tutorials || [];
  }, [selectedGame]);

  const closeTutorialModal = useCallback(() => {
    closeModal(TUTORIAL_MODAL_ID);
  }, [closeModal]);

  const updateProgress = useCallback(() => {
    if (videoRef.current) {
      const percent = (videoRef.current.currentTime / videoRef.current.duration) * 100;
      setProgress(percent);
      animationFrameRef.current = requestAnimationFrame(updateProgress);
    }
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      setProgress(0);
      void videoRef.current.play();
      animationFrameRef.current = requestAnimationFrame(updateProgress);
      setVideoAction("play");
    }

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [currentStep, updateProgress]);

  const handleVideoEnd = useCallback(() => {
    setStep(undefined);
    setCurrentStep((prevStep) => (prevStep + 1) % tutorialSteps.length);
  }, [tutorialSteps]);

  useEffect(() => {
    if (videoAction !== undefined) {
      if (videoRef.current) {
        if (videoAction === "stop") {
          videoRef.current.pause();
        } else if (videoAction === "play") {
          void videoRef.current.play();
          animationFrameRef.current = requestAnimationFrame(updateProgress);
        }
      }
    }
  }, [videoAction]);

  useEffect(() => {
    if (step !== undefined) {
      setCurrentStep(step);
    }
  }, [step]);

  useEffect(() => {
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      removeStep();
    };
  }, []);

  useEffect(() => {
    if (!isModalVersion) {
      return;
    }

    if (!isModalOpen(TUTORIAL_MODAL_ID)) {
      removeStep();
      setCurrentStep(tutorialSteps.length - 1);
    }
  }, [isModalVersion, isModalOpen(TUTORIAL_MODAL_ID)]);

  return (
    <>
      {!isModalVersion && (
        <div className={styles.container}>
          <div className="center">
            {imageError ? (
              <span className={styles.gameName}>{selectedGame?.name}</span>
            ) : (
              <img
                id="text-game-icon"
                src={`/logos/${selectedGame?.gameId}.png`}
                className={styles.gameIcon}
                onError={() => setImageError(true)}
              />
            )}
          </div>
          <div className={styles.tutorialScreen}>
            {/* Video Section */}
            <div className={styles.videoContainer}>
              <video
                ref={videoRef}
                src={tutorialSteps[currentStep].src}
                className={styles.video}
                onEnded={handleVideoEnd}
                controls={false}
                muted
                playsInline
              />
            </div>

            {/* Accordion Section */}
            <div className={styles.accordionContainer}>
              {tutorialSteps.map((step, index) => (
                <AccordionItem key={step.id} title={t(step.title)} isActive={currentStep === index}>
                  <div className={styles.progressContainer}>
                    <ProgressBar progress={currentStep === index ? progress : 0} />
                  </div>
                  <span className="display-m regular white-alpha-70">{t(step.description)}</span>
                </AccordionItem>
              ))}
            </div>
          </div>
        </div>
      )}

      {isModalVersion && (
        <Modal
          isOpen={isModalOpen(TUTORIAL_MODAL_ID)}
          outsideTitle={t("how_to_play")}
          modalClassName={styles.tutorialModalContainer}
          onCancel={closeTutorialModal}
        >
          <div className={cn(styles.tutorialScreen, { [styles.isModal]: isModalVersion })}>
            {/* Video Section */}
            <div className={styles.videoContainer}>
              <video
                ref={videoRef}
                src={tutorialSteps[currentStep].src}
                className={cn(styles.video, { [styles.isModal]: isModalVersion })}
                onEnded={handleVideoEnd}
                controls={false}
                muted
                playsInline
              />
            </div>

            {/* Accordion Section */}
            <div className={styles.accordionContainer}>
              {tutorialSteps.map((step, index) => (
                <AccordionItem key={step.id} title={t(step.title)} isActive={currentStep === index}>
                  <div className={styles.progressContainer}>
                    <ProgressBar progress={currentStep === index ? progress : 0} />
                  </div>
                  <span className="text-l regular white-alpha-70">{t(step.description)}</span>
                </AccordionItem>
              ))}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default GameTutorialScreen;
