import React, { useMemo, useState } from "react";

import cn from "classnames";
import { useTranslation } from "react-i18next";
import Game from "../../models/game";
import InGamePlayers from "../../components/InGamePlayers/in-game-players";
import GameTutorialScreen from "../GameTutorial/game-tutorial-screen";
import { useGameStateStore } from "../../store/gameStateStore";
import { useApplicationStore } from "../../store/applicationStore";
import styles from "./styles.module.scss";

const systemGames = {
  "killer-new": { enabled: true },
  "shanghai-new": { enabled: true },
  lumberjack: { enabled: false },
  meteor: { enabled: false },
  zombie: { enabled: false },
};

interface GameListItemProps {
  index: number;
  game: Game;
  enabled: boolean;
}

const ComingSoonBadge = ({ index }: { index: number }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.comingSoonBadge} style={{ animationDelay: `${index * 0.25}s` }}>
      <span className="text-m regular uppercase">{t("coming_soon")}</span>
    </div>
  );
};

const GameListItem = ({ index, game, enabled }: GameListItemProps) => {
  const [videoError, setVideoError] = useState(false);

  const handleVideoError = () => {
    setVideoError(true);
  };

  return (
    <div style={{ position: "relative" }}>
      {!enabled && <ComingSoonBadge index={index} />}
      <div
        className={cn(styles.gameContainer, { [styles.disabled]: !enabled })}
        style={{ animationDelay: `${index * 0.25}s` }}
      >
        {videoError ? (
          <div className={styles.placeholder}>
            <span>{game.name}</span>
          </div>
        ) : (
          <video
            src={`/animated_logos_v2/${game.gameId}_v1_1024.mp4`}
            autoPlay
            loop
            muted
            playsInline
            onError={handleVideoError}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        )}
      </div>
    </div>
  );
};

export default function GameListScreen() {
  const { t } = useTranslation();
  const selectedGame = useGameStateStore((state) => state.selectedGame);
  const gameList = useGameStateStore((state) => state.gameList);
  const background = useApplicationStore((state) => state.background);

  const allowedGames = useMemo(
    () => gameList.filter((game) => Object.keys(systemGames).some((key) => key === game.gameId)),
    [gameList],
  );

  const videoSource = useMemo(() => {
    return selectedGame ? `/game_background/${selectedGame?.gameId}_bg.mp4` : `/backgrounds/${background}.mp4`;
  }, [selectedGame, background]);

  return (
    <>
      <video src={videoSource} muted autoPlay playsInline loop className={styles.gameVideoBackground} />
      {selectedGame && <div className="shadow-bg"></div>}
      {!selectedGame && (
        <div>
          <InGamePlayers />
          <div className={styles.wrapper}>
            <div className="center">
              <span className="display-xl bold uppercase mt-6">{t("select_game")}</span>
            </div>
            <div className={styles.gameListContainer}>
              {allowedGames.length > 0 &&
                allowedGames.map((game, index) => (
                  <GameListItem
                    index={index}
                    key={game.gameId}
                    game={game}
                    enabled={systemGames[game.gameId as keyof typeof systemGames]?.enabled}
                  />
                ))}
            </div>
          </div>
        </div>
      )}
      {selectedGame && <GameTutorialScreen />}
    </>
  );
}
