import React from "react";
import cn from "classnames";
import { usePlayerStore } from "../../store/playerStore";
import styles from "./styles.module.scss";

const InGamePlayers = () => {
  const playerList = usePlayerStore((state) => state.playerList);

  return (
    <div className={cn(styles.playerListContainer, "d-flex")}>
      {playerList.map((player) => (
        <div key={player.name} className={cn(styles.playerImageContainer, "mx-1", `bg-${player?.color}`)}>
          {!player.image && <div className={styles.capitalLetter}> {player.name.charAt(0).toUpperCase()} </div>}
          {player.image && <img src={player.image} alt={player.name} className={styles.playerImage} />}
        </div>
      ))}
    </div>
  );
};

export default InGamePlayers;
