import { createWithEqualityFn } from "zustand/traditional";
import { combine } from "zustand/middleware";
import { shallow } from "zustand/shallow";
import * as apiService from "../services/api-service";
import Game from "../models/game";

interface GameState {
  code: string | null;
  gameList: Game[];
  gameScene: string;
  selectedGame: Game | null;
}

const initialState: GameState = {
  code: null,
  gameList: [],
  gameScene: "start_screen",
  selectedGame: null,
};

interface GameStateMutations {
  setSelectedGame: (game: Game) => void;
  updateGameConfiguration: (values: object) => void;
  setGameScene: (scene: string) => void;
  setCode: (code: string | null) => void;
  loadGames: () => Promise<void>;
  reset: () => void;
}

export type GameStateStore = GameState & GameStateMutations;

const mutations = (set: (partial: Partial<GameStateStore>) => void, get: () => GameState): GameStateMutations => ({
  setSelectedGame: (selectedGame: Game) => set({ selectedGame }),

  updateGameConfiguration: (values: object) => {
    const game = get().selectedGame;
    if (!game) return;

    return set({
      selectedGame: {
        ...game,
        configuration: {
          ...game.configuration,
          ...values,
        },
      },
    });
  },
  setGameScene: (gameScene: string) => set({ gameScene }),
  setCode: (code: string | null) => set({ code }),

  loadGames: async () => {
    let games;

    try {
      games = await apiService.loadGameList();
    } catch (e) {
      console.log(e);
      games = [];
    }

    set({ gameList: games });
  },
  reset: () => set({ selectedGame: null, gameScene: "start_screen" }),
});

export const useGameStateStore = createWithEqualityFn(combine(initialState, mutations), shallow);

/*
 set: {
    (
      partial:
        | SelectedGameState
        | Partial<SelectedGameState>
        | ((state: SelectedGameState) => SelectedGameState | Partial<SelectedGameState>),
      replace?: false | undefined,
    ): void;
    (state: SelectedGameState | ((state: SelectedGameState) => SelectedGameState), replace: true): void;
  },
*/
