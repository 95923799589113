import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
import Player from "../models/player";

export interface PlayerState {
  playerList: Player[];
  updatePlayerList: (players: Player[]) => void;
}

export const usePlayerStore = createWithEqualityFn<PlayerState>(
  (set) => ({
    playerList: [],
    updatePlayerList: (playerList: Player[]) => {
      return set(() => ({
        playerList,
      }));
    },
  }),
  shallow,
);
