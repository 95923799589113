import React, { PropsWithChildren, useEffect, useState } from "react";
import cn from "classnames";
import Close from "@material-ui/icons/Close";
import styles from "./styles.module.scss";

interface ModalProps {
  isOpen: boolean;
  title?: string;
  modalClassName?: string;
  outsideTitle?: string;
  onCancel?: () => void;
}

export default function Modal({
  title,
  isOpen,
  children,
  modalClassName,
  outsideTitle,
  onCancel,
}: PropsWithChildren<ModalProps>) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(isOpen);
  const [isVisible, setIsVisible] = useState<boolean>(isOpen); // Controls visibility for animations

  const handleCloseClick = () => {
    if (onCancel) {
      onCancel();
    }
  };

  useEffect(() => {
    if (isOpen) {
      setIsModalOpen(true); // Ensure the component is mounted
      setTimeout(() => setIsVisible(true), 100); // Trigger enter animation
    } else {
      setIsVisible(false); // Trigger exit animation
      setTimeout(() => setIsModalOpen(false), 0); // Adjust timeout to match your exit animation duration
    }
  }, [isOpen]);

  if (!isModalOpen) return null;

  return (
    <div className={styles.modalContainer}>
      <div
        className={cn(styles.modal, modalClassName, {
          [styles.isLoaded]: isVisible, // This class toggles visibility for animations
        })}
      >
        {outsideTitle && (
          <span className={cn(styles.animatedText, "text-center display-xl bold uppercase mb-14")}>{outsideTitle}</span>
        )}

        <div className={styles.modalContent}>
          <div className={styles.modalHeader}>
            {title && <label className="display-s bold uppercase">{title}</label>}
          </div>
          <div className={styles.modalBody}>{children}</div>
        </div>
      </div>
    </div>
  );
}
