import { NavigateFunction } from "react-router-dom";
import GameService from "../services/game-service";
import { incomingMessageTypes, outgoingMessageTypes } from "../constants/message-types";
import GameConfiguration from "../models/game-configuration";
import SbcStatus from "../models/sbc-status";
import SbcRegistrationError from "../models/sbcRegistrationError";
import { routes, SERIAL_NUMBER } from "../App";
import Player from "../models/player";
import { DisplayDeviceData } from "../models/display-device-data";
import Game from "../models/game";
import { VideoAction } from "../store/tutorialStore";
import { AplicationStore } from "../store/utils";
import { TUTORIAL_MODAL_ID } from "../containers/GameTutorial/game-tutorial-screen";
import { LeaderBoard } from "../models/leaderboard";
import { LEADERBOARD_MODAL_ID } from "../containers/Leaderboard/leaderboard";
import i18n from "../i18n";

export const REFRESH_TOKEN = "refreshToken";
const SHOW_TAKE_OVER_CONTROL_TIMEOUT = 10000;
const HIDE_TAKE_OVER_CONTROL_TIMEOUT = 5000;

export class EventHandler {
  private eventStartedTimeout: NodeJS.Timeout | null = null;
  private eventFinishedTimeout: NodeJS.Timeout | null = null;
  private isPopupVisible: boolean = false;

  constructor(
    private navigate: NavigateFunction,
    private emit: (message: string, payload?: any) => void,
    private applicationStore: AplicationStore,
  ) {}

  public handle(event: string, payload?: any): void {
    switch (event) {
      case incomingMessageTypes.gameConfigurationChanged:
        this.onGameConfigurationChanged(payload);
        break;
      case incomingMessageTypes.unlink:
        this.onUnlink();
        break;
      case incomingMessageTypes.codeResponse:
        this.onCodeResponse(payload);
        break;
      case incomingMessageTypes.selectGameRequest:
        this.onSelectGameRequest(payload);
        break;
      case incomingMessageTypes.startGame:
        this.onStartGame();
        break;
      case incomingMessageTypes.abortGame:
        this.onAbortGame();
        break;
      case incomingMessageTypes.endGame:
        this.onEndGame();
        break;
      case incomingMessageTypes.throw:
        GameService.throwDetected(payload);
        break;
      case incomingMessageTypes.takeoutStarted:
        this.onTakeoutStarted(payload);
        break;
      case incomingMessageTypes.takeoutFinished:
        this.onTakeoutFinished(payload);
        break;
      case incomingMessageTypes.loadGame:
        this.onLoadGame();
        break;
      case incomingMessageTypes.playerListChanged:
        this.onPlayerListChanged(payload);
        break;
      case incomingMessageTypes.sbcStatusChanged:
        this.onSbcStatusChanged(payload);
        break;
      case incomingMessageTypes.sbcRegistrationError:
        this.onSbcRegistrationError(payload);
        break;
      case incomingMessageTypes.reconnect:
        this.onReconnect();
        break;
      case incomingMessageTypes.linkResponse:
        this.onLinkResponse(payload);
        break;
      case incomingMessageTypes.connect:
        this.onConnect();
        break;
      case incomingMessageTypes.updateRefreshToken:
        this.updateRefreshToken(payload);
        break;
      case incomingMessageTypes.tutorialAction:
        this.handleTutorialAction(payload);
        break;
      case incomingMessageTypes.gameSceneChanged:
        this.handleGameSceneChanged(payload);
        break;
      case incomingMessageTypes.getLeaderboardResponse:
        this.handleLeaderboardResponse(payload);
        break;
      case incomingMessageTypes.leaderboardModalStateChanged:
        this.handleLeaderboardModalStateChanged(payload);
        break;

      case incomingMessageTypes.changeLanguage:
        this.handleChangeLanguage(payload);
        break;
    }
  }

  private onGameConfigurationChanged(values: GameConfiguration): void {
    GameService.updateGameConfiguration(values);
  }

  private onUnlink(): void {
    sessionStorage.setItem(REFRESH_TOKEN, "");
    GameService.reset();
    this.applicationStore.reset();
    this.navigate(`/?serialNumber=${sessionStorage.getItem(SERIAL_NUMBER)}`);
  }

  private onCodeResponse({ code }: { code: string }): void {
    this.applicationStore.setCode(code);
  }

  private onSelectGameRequest(game: Game): void {
    this.applicationStore.setSelectedGame(game);
  }

  private onStartGame(): void {
    this.navigate("/game");
    GameService.startGame();
  }

  private onLoadGame(): void {
    this.navigate("/load-game");
  }

  private onAbortGame(): void {
    GameService.reset();
    this.navigate("/game-list");
  }

  private onEndGame(): void {
    GameService.reset();
    this.navigate("/leaderboard");
  }

  private onSbcStatusChanged(sbcStatus: SbcStatus): void {
    this.applicationStore.setSbcStatus(sbcStatus?.status);
  }

  private onSbcRegistrationError(sbcRegistrationError: SbcRegistrationError): void {
    if (sbcRegistrationError.reason === "in_use") {
      this.applicationStore.setSbcStatus(sbcRegistrationError.reason);
    }
  }

  private onLinkResponse({
    success,
    linkToken,
    displayDeviceData,
  }: {
    success: boolean;
    linkToken: string;
    msg: string;
    displayDeviceData: DisplayDeviceData;
  }): void {
    if (!success) {
      this.onUnlink();
      return;
    }

    sessionStorage.setItem(REFRESH_TOKEN, linkToken ?? "");

    const { playerList, selectedGame, gameScene, inGameDisplayDeviceCount, language } = displayDeviceData || {
      playerList: [],
      selectedGame: null,
      gameScene: "/",
      inGameDisplayDeviceCount: 1,
    };

    if (language) {
      void i18n.changeLanguage(language);
    }

    if (selectedGame) {
      this.applicationStore.setSelectedGame(selectedGame);
    }

    if (playerList) {
      this.onPlayerListChanged(playerList);
    }

    if (gameScene) {
      this.applicationStore.setGameScene(gameScene);
    }

    if (gameScene !== "in_game" || inGameDisplayDeviceCount === 0) {
      if (routes.map(({ path }) => path).includes(gameScene)) {
        this.navigate(gameScene);
        return;
      }

      if (gameScene === "in_game") {
        this.navigate("/game-list"); //TODO
        return;
      }

      this.navigate("/player-list"); //TODO
    }
  }

  private onReconnect(): void {
    const refreshToken = sessionStorage.getItem(REFRESH_TOKEN);

    if (refreshToken) {
      const code: string = refreshToken;
      this.emit(outgoingMessageTypes.linkRequestWithToken, { code });
    }
  }

  private requestCode(): void {
    this.applicationStore.setCode(null);
    this.emit(outgoingMessageTypes.codeRequest);
  }

  private onPlayerListChanged(players: Player[]): void {
    this.applicationStore.updatePlayerList(players);
    GameService.updatePlayerList(players);
  }

  private onConnect(): void {
    const refreshToken = sessionStorage.getItem(REFRESH_TOKEN);

    if (refreshToken) {
      this.onReconnect();
    } else {
      this.requestCode();
    }
  }

  private updateRefreshToken({ linkToken }: { linkToken: string }): void {
    sessionStorage.setItem(REFRESH_TOKEN, linkToken);
  }

  private onTakeoutStarted(payload: any): void {
    GameService.takeoutStarted(payload);
    this.handleEventStarted();
  }

  private onTakeoutFinished(payload: any): void {
    GameService.takeoutFinished(payload);
    this.handleEventFinished();
  }

  private handleEventStarted() {
    this.clearTimers();

    this.eventStartedTimeout = setTimeout(() => {
      this.showTakeOverControl();
    }, SHOW_TAKE_OVER_CONTROL_TIMEOUT);
  }

  private handleEventFinished() {
    this.clearTimers();

    if (!this.isPopupVisible) {
      return;
    }

    this.eventFinishedTimeout = setTimeout(() => {
      this.hideTakeOverControl();
    }, HIDE_TAKE_OVER_CONTROL_TIMEOUT);
  }

  private showTakeOverControl() {
    this.isPopupVisible = true;
    this.applicationStore.setTakeOverControlVisible(true);
  }

  private hideTakeOverControl() {
    this.isPopupVisible = false;
    this.applicationStore.setTakeOverControlVisible(false);
  }

  private clearTimers() {
    if (this.eventStartedTimeout) {
      clearTimeout(this.eventStartedTimeout);
      this.eventStartedTimeout = null;
    }

    if (this.eventFinishedTimeout) {
      clearTimeout(this.eventFinishedTimeout);
      this.eventFinishedTimeout = null;
    }
  }

  private handleTutorialAction(action: {
    step?: number;
    videoAction?: VideoAction;
    isTutorialModalOpen?: boolean;
  }): void {
    if (action.step !== undefined) {
      this.applicationStore.setStep(action.step);
    }

    if (action.videoAction !== undefined) {
      this.applicationStore.setVideoAction(action.videoAction);
    }

    if (action.isTutorialModalOpen !== undefined) {
      if (action.isTutorialModalOpen) {
        this.applicationStore.openModal(TUTORIAL_MODAL_ID);
      } else {
        this.applicationStore.closeModal(TUTORIAL_MODAL_ID);
      }
    }
  }

  private handleGameSceneChanged(scene: string): void {
    this.navigate(scene);
  }

  private handleLeaderboardResponse(leaderboard: LeaderBoard[]): void {
    this.applicationStore.setLeaderboard(leaderboard);
  }

  private handleLeaderboardModalStateChanged({ leaderboardModalOpen }: { leaderboardModalOpen: boolean }): void {
    if (leaderboardModalOpen) {
      this.applicationStore.openModal(LEADERBOARD_MODAL_ID);
    } else {
      this.applicationStore.closeModal(LEADERBOARD_MODAL_ID);
    }
  }

  private handleChangeLanguage({ language, background }: { language?: string; background?: string }): void {
    if (language) {
      void i18n.changeLanguage(language);
    }

    if (background) {
      this.applicationStore.setBackground(background);
    }
  }
}
