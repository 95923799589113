import { Box } from "@material-ui/core";
import React, { useEffect, useRef, useContext, useCallback } from "react";
import { Navigate } from "react-router-dom";
import SocketContext from "../context/socket-context";
import GameService from "../services/game-service";
import { outgoingMessageTypes } from "../constants/message-types";
import { GameResult } from "../models/game-result";
import { useGameStateStore } from "../store/gameStateStore";
import GameTutorialScreen from "./GameTutorial/game-tutorial-screen";

const GameScreen: React.FC = () => {
  const socket = useContext(SocketContext);
  const gameArea = useRef<HTMLIFrameElement>(null);
  const selectedGame = useGameStateStore((state) => state.selectedGame);

  const setDisplayGameScene = useCallback(() => {
    socket?.emit(outgoingMessageTypes.gameSceneChanged, "in_game");
  }, []);

  useEffect(() => {
    GameService.setGameWindow(gameArea.current?.contentWindow);
    GameService.onGameReady(() => socket?.emit(outgoingMessageTypes.gameReady));
    GameService.onGameOver((gameResult: GameResult) => socket?.emit(outgoingMessageTypes.gameOver, gameResult));
    GameService.onPlayerEliminated((eliminatedPlayer: { player: string }) =>
      socket?.emit(outgoingMessageTypes.playerEliminated, eliminatedPlayer.player),
    );
  }, []);

  useEffect(() => {
    setDisplayGameScene();
  }, []);

  if (!selectedGame) {
    return <Navigate to="/" />;
  }

  return (
    <Box width={1} overflow="hidden">
      <GameTutorialScreen isModalVersion={true} />
      <Box position="relative">
        <iframe
          ref={gameArea}
          title="game-area"
          src={selectedGame.path}
          style={{
            border: 0,
            padding: 0,
            margin: 0,
            width: "100%",
            height: "100vh",
          }}
        />
      </Box>
    </Box>
  );
};

export default GameScreen;
