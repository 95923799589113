import { QRCode } from "react-qrcode-logo";
import { useMediaQuery } from "@material-ui/core";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { URI } from "../constants/config";
import { getConfigDeviceUrl } from "../utils/configurator-device-url";

import { useGameStateStore } from "../store/gameStateStore";
import SocketContext from "../context/socket-context";
import { outgoingMessageTypes } from "../constants/message-types";
import { useApplicationStore } from "../store/applicationStore";
import styles from "./styles.module.scss";

const getDomainFromUrl = (url: string) => {
  return new URL(getConfigDeviceUrl(url)).hostname.toUpperCase();
};

const StartScreen = () => {
  const { t } = useTranslation();
  const socket = useContext(SocketContext);
  const { code, loadGames } = useGameStateStore((state) => state);
  const sbcStatus = useApplicationStore((state) => state.sbcStatus);
  const gameScene = useGameStateStore((state) => state.gameScene);

  const introRef = useRef<HTMLVideoElement>(null);
  const loopRef = useRef<HTMLVideoElement>(null);
  const [currentVideo, setCurrentVideo] = useState("intro");

  const fullHdView = useMediaQuery("(min-width:1920px) and (min-height:1080px)");

  const [configDeviceConnectUrl, setConfigDeviceConnectUrl] = useState(getConfigDeviceUrl(URI));

  const isInGame = useMemo(() => gameScene === "in_game", [gameScene]);

  useEffect(() => {
    socket?.emit(outgoingMessageTypes.codeRequest);
    void loadGames();
  }, []);

  useEffect(() => {
    if (sbcStatus === "online") {
      setConfigDeviceConnectUrl(`${getConfigDeviceUrl(URI)}/?accessCode=${code}`);
    } else {
      setConfigDeviceConnectUrl(getConfigDeviceUrl(URI));
    }
  }, [sbcStatus, code]);

  const handleIntroEnd = () => {
    setCurrentVideo("loop");
  };

  const updateVideoDisplay = useCallback(() => {
    if (introRef.current) introRef.current.style.display = currentVideo === "intro" ? "block" : "none";
    if (loopRef.current) loopRef.current.style.display = currentVideo === "loop" ? "block" : "none";
  }, [currentVideo]);

  useEffect(() => {
    updateVideoDisplay();
  }, [currentVideo, updateVideoDisplay]);

  return (
    <div className={styles.container}>
      <div className={styles.videoContainer}>
        <video ref={introRef} src="/start_screen/intro.mp4" muted autoPlay playsInline onEnded={handleIntroEnd} />

        <video ref={loopRef} src="/start_screen/loop.mp4" muted autoPlay playsInline loop />
      </div>

      <div className={styles.loginCard}>
        <span className="display-xl bold uppercase text-center w-100 pb-10">{t("want_to_play")}</span>

        <div className={styles.qrCodeCard}>
          <span className="text-xl regular uppercase white-alpha-70 pb-6">{t("follow_qr")}</span>

          <div className={styles.qrCode}>
            <QRCode
              value={configDeviceConnectUrl}
              style={{
                borderRadius: "16px",
                width: fullHdView ? "250px" : "200px",
                height: fullHdView ? "250px" : "200px",
                boxShadow: "0px 2px 6px 0px #0000001a, 0px 0px 2px 0px #00000014, 0px 0px 0px 1px #00000033",
              }}
            />
          </div>
        </div>

        <span className="display-s bold uppercase my-auto text-center">{t("or")}</span>

        <div className={styles.infoCard}>
          <div className="center flex-row pb-2">
            <span className={styles.badge}>1</span>
            <span className="text-xl regular uppercase white-alpha-70 pl-2">{t("visit_this_page")}</span>
          </div>

          <span className="display-m bold uppercase pb-8">{getDomainFromUrl(URI)}</span>

          <div className="center flex-row pb-2">
            <span className={styles.badge}>2</span>
            <span className="text-xl regular uppercase white-alpha-70 pl-2">{t("enter_code")}</span>
          </div>

          {sbcStatus === "online" && !isInGame && <span className="display-m bold uppercase"> {code}</span>}

          {sbcStatus === "offline" && !isInGame && (
            <div className={cn(styles.errorContainer, "center flex-row mt-8")}>
              <ErrorOutline className={cn(styles.error, "mr-2")} />
              <span className="text-xl regular uppercase">{t("offline")}</span>
            </div>
          )}

          {isInGame && (
            <div className={cn(styles.errorContainer, "center flex-row mt-8")}>
              <ErrorOutline className={styles.error} />
              <div>
                <p className="m-0 text-xl regular uppercase">{t("board_is_in_play")}</p>
                <p className="m-0 text-l regular white-alpha-70">{t("wait_for_finish")}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StartScreen;
