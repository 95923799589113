import io, { Socket } from "socket.io-client";
import { NavigateFunction } from "react-router-dom";
import { incomingMessageTypes } from "../constants/message-types";
import { URI } from "../constants/config";
import { AplicationStore } from "../store/utils";
import { EventHandler } from "./event-handler";

export const initSocket = (serialNumber: string): Socket =>
  io(URI, { query: { type: "display", token: serialNumber } });

export const initEventHandler = (navigate: NavigateFunction, socket: Socket, applicationStore: AplicationStore) => {
  if (!applicationStore) return;

  const emit = (type: string, payload?: any) => socket.emit(type, payload);
  const handler = new EventHandler(navigate, emit, applicationStore);
  Object.values(incomingMessageTypes).forEach((message) => {
    socket.on(message, (payload: any) => handler.handle(message, payload));
  });
};
