import i18n, { use } from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { DEFAULT_LANGUAGE, TRANSLATIONS_BASE_URL } from "./constants/config";

void use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    initImmediate: false,
    fallbackLng: DEFAULT_LANGUAGE || "en",
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["localStorage", "cookie", "navigator"],
      caches: ["localStorage", "cookie"],
      lookupLocalStorage: "i18nextLng",
    },
    backend: {
      loadPath: `${TRANSLATIONS_BASE_URL}/{{lng}}`,
    },
  });

// Ensure only the base language is used
i18n.on("languageChanged", (lng) => {
  const shortLang = lng.split("-")[0];
  if (lng !== shortLang) {
    void i18n.changeLanguage(shortLang);
  }
});

export default i18n;
