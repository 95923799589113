import React, { useMemo, useState, useEffect } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import Player from "../../models/player";
import { usePlayerStore } from "../../store/playerStore";
import { useApplicationStore } from "../../store/applicationStore";
import styles from "./styles.module.scss";

interface PlayerCardProps {
  player: Player;
}

export const PlayerCard = ({ player }: PlayerCardProps) => {
  return (
    <div className="center">
      <div className={cn(styles.playerItem, `bg-${player.color}`)}>
        {player.image && <img src={player.image} alt={player.name} className={styles.playerImage} />}
        {!player.image && <div className={styles.capitalName}> {player.name.charAt(0).toUpperCase()} </div>}
      </div>
      <div className={"mt-4 display-m bold uppercase"}>{player.name}</div>
    </div>
  );
};

export default function PlayerListScreen() {
  const { t, i18n } = useTranslation();
  const playerList = usePlayerStore((state) => state.playerList);
  const background = useApplicationStore((state) => state.background);
  const [addingPlayerText, setAddingPlayerText] = useState<string[] | null>(null);

  useEffect(() => {
    setAddingPlayerText(null); // Set to null initially
    const timeoutId = setTimeout(() => {
      setAddingPlayerText(t("start_adding_players").split(""));
    }, 100); // Delay to simulate the text update

    return () => clearTimeout(timeoutId);
  }, [i18n.language, t]);

  return (
    <>
      <video
        src={`/backgrounds/${background}.mp4`}
        muted
        autoPlay
        playsInline
        loop
        className={styles.videoBackground}
      />
      <div className={styles.setupPlayers}>
        <span className={cn(styles.title, "display-xl bold uppercase")}>{t("set_up_players")}</span>

        {playerList.length > 0 && (
          <div className={styles.playerContainer}>
            {playerList.map((player, idx) => (
              <PlayerCard key={idx} player={player} />
            ))}
          </div>
        )}

        {playerList.length === 0 && addingPlayerText && (
          <div className="display-xxxl regular">
            {addingPlayerText.map((letter, idx) => (
              <span key={idx} className={styles.animatedText} style={{ animationDelay: `${idx * 0.05}s` }}>
                {letter}
              </span>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
